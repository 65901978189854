/* Global Styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #051B22;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Ensure DM Sans is applied */
html, body, .container, .row, .col, .btn {
  font-family: 'DM Sans', sans-serif !important;
}

/* App Styles */
.App {
  text-align: center;
}

/* Section Styles */
.questions-section .img-fluid,
.solution-section .img-fluid {
  max-height: 600px;
  object-fit: cover;
}

.values-section {
  background: linear-gradient(90deg, #6a11cb 0%, #2575fc 100%);
  color: white;
}

.values-section .card {
  background: rgba(255, 255, 255, 0.8);
  border: none;
}

.values-section .container {
  position: relative;
  z-index: 1;
}

.values-img {
  max-height: 80% !important;
  right: 0px;
  bottom: 0px;
}

.footer {
  background-color: #6a11cb;
  color: white;
  padding: 20px 0;
  text-align: center;
}

/* Calculator Section Styles */
.calculator-section .card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.calculator-section .card .row {
  align-items: center;
}

.calculator-section .card .col-md-4 {
  display: flex;
  justify-content: center;
}

.calculator-section .card .col-md-8 {
  padding-left: 20px;
}

.calculator-section .card .text-muted {
  font-size: 0.8rem;
}

/* Slide Styles */
.slide {
  /* max-height: 80vh; */
  position: relative;
  display: flex;
  align-items: center; /* Vertically align content */
  justify-content: center; /* Horizontally align content (optional) */
  min-height: 550px;
  height: 100vh;
}

.slide > .container {
  height: auto; /* Let the content define the height */
  position: relative;
  display: flex;
  align-items: center; /* Vertically align content */
  justify-content: center; /* Horizontally align content (optional) */
  flex-direction: column; /* Align items vertically */
}

.slide-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.slide-background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Image Styles */
img.left {
  object-position: left top;
}

img.contain {
  object-fit: contain;
}

img.right-force {
  object-position: right top !important;
}

img.bottom {
  object-position: left bottom !important;
}

@media (min-width: 1200.05px) {
  img.right {
    object-position: center top !important;
  }
}

@media (max-width: 767px) {
  img.right {
    object-position: center top !important;
  }
}

/* Button Styles */
header .btn {
  font-size: 21px;
}

/* Form Styles */
#calculator form {
  display: flex;
  align-items: flex-end; /* Aligns content to the bottom */
  justify-content: center; /* This centers the text horizontally, if desired */
  height: 100%; /* Ensure the parent element has a defined height */
}


.btn-primary {
  background-color: rgb(156, 241, 16);
  border-color: rgb(156, 241, 16);
}

.btn-primary:hover {
  background-color: rgba(156, 241, 16, 0.8);
  border-color: rgba(156, 241, 16, 0.8);
}

/* custom.css */
.custom-modal .modal-dialog {
  width: 100vw;
  max-width: 800px;
}

.custom-modal .modal-content {
  width: 100%;
}
